// class Placeholder {
//     constructor(that) {
//         this.firstName = '{first_name}';
//         this.lastName = '{last_name}';
//         this.email = '{email_address}';
//         this.phone = '{phone_number}';
//         this.survey_url = '{survey_url}';
//         // this.recommendation_url = '{recommendation_url}';
//         this.that = that;
//     }
//
//     insertPlaceholder( val, theObject, target) {
//         let that = this.that;
//         let element = that.$refs[target];
//         let cursorStart = element.selectionStart;
//         if (that[theObject][target] == null) {
//             that[theObject][target] = '';
//         }
//
//         that[theObject][target] = that[theObject][target].substring(0, cursorStart) + val + that[theObject][target].substring(cursorStart, that[theObject][target].length);
//     }
// }

class Placeholder {
    constructor(that) {
        this.options = [];

        this.options.push({
            label: '#Kundendaten',
            id: 'P_Kundendaten',
            children: [],
        });
        this.options[0].children.push({
            label: '#Vorname',
            id: '{customer:first_name}',
            color: 'warning',
        });
        this.options[0].children.push({
            label: '#Nachname',
            id: '{customer:last_name}',
            color: 'warning'
        });
        this.options[0].children.push({
            label: '#Kunden-EMail',
            id: '{customer:email_address}',
            color: 'warning',
        });
        this.options[0].children.push({
            label: '#Telefonnummer',
            id: '{customer:phone_number}',
            color: 'warning',
        });
        this.options[0].children.push({
            label: '#Mitarbeiter-EMail',
            id: '{customer:employee_email}',
            color: 'warning',
        });
        this.options[0].children.push({
            label: '#CRM ID',
            id: '{customer:crm_id}',
            color: 'warning',
        });

        this.options.push({
            label: '#Teilnehmerdaten',
            id: 'P-Teilnehmerdaten',
            children: [],
        });
        this.options[1].children.push({
            label: '#Vorname',
            id: '{subscriber:first_name}',
            color: 'secondary',
        });
        this.options[1].children.push({
            label: '#Nachname',
            id: '{subscriber:last_name}',
            color: 'secondary'
        });
        this.options[1].children.push({
            label: '#Kunden-EMail',
            id: '{subscriber:email_address}',
            color: 'secondary',
        });
        this.options[1].children.push({
            label: '#Telefonnummer',
            id: '{subscriber:phone_number}',
            color: 'secondary',
        });

        this.options.push({
            label: '#Advanced',
            id: 'P-Advanced',
            children: [],
        });
        this.options[2].children.push({
            label: '#Kunden URL',
            id: '{customer}',
            color: 'primary',
        });
        this.options[2].children.push({
            label: '#Umfrage URL',
            id: '{survey_url}',
            color: 'primary',
        });
        this.options[2].children.push({
            label: '#QR Code',
            id: '{qr_code}',
            color: 'primary',
        });

        this.that = that;
    }

    buildSurvey(surveys) {
        let surveysEntry = {
            id: 'P-Umfragen',
            label: '#Umfragen',
            children: [],
        };

        surveys.forEach((survey) => {
            let s = {
                id: survey.id,
                label: survey.name,
                color: 'success',
                children: [],
            };

            s.children.push({
                id: '{survey:' + survey.id + '}',
                label: '#Umfrage: ' + survey.name,
                color: 'info',
            });

            survey.topics.forEach((topic) => {
                let t = {
                    id: topic.id,
                    label: topic.name,
                    color: 'success',
                    children: [],
                };
                t.children.push({
                    id: '{topic:' + topic.id + '}',
                    label: '#Kategorie: ' + topic.name,
                    color: 'info',
                });

                topic.questions.forEach((question) => {
                    let q = {
                        id: question.id,
                        label: question.name,
                        color: 'success',
                        children: [],
                    };

                    q.children.push({
                        id: '{question:' + question.id + '}',
                        label: '#Frage u. Antwort',
                        color: 'success',
                    });
                    q.children.push({
                        id: '{question_name:' + question.id + '}',
                        label: '#Fragenname',
                        color: 'success',
                    });
                    q.children.push({
                        id: '{question_answer:' + question.id + '}',
                        label: '#Antwort',
                        color: 'success',
                    });

                    if (question.type === 'contact') {
                        if (question.option_2 != null && question.option_2.length > 0) {
                            question.option_2.forEach(contact => {
                                q.children.push({
                                    id: '{question_contact:' + contact.id + '}',
                                    label: '#Kontaktfeld: ' + contact.name,
                                    color: 'success',
                                });
                            });
                        }
                    }

                    t.children.push(q);
                });
                s.children.push(t);
            });
            surveysEntry.children.push(s);
        });

        this.options.push(surveysEntry);
    }

    replacePlaceholder(message) {
        this.options.forEach(options => {
            message = this.recursiveReplace(message, options);
        });

        return message;
    }

    recursiveReplace(message, option) {
        if ('children' in option) {
            option.children.forEach(op => {
                message = this.recursiveReplace(message, op);
            });
        }
        else {
            if (message.includes(option.id)) {
                message = message.replace(option.id, '<span class="badge badge-' + option.color + '" data-marker="' + option.id + '" data-title="' + option.label + '" data-color="' + option.color + '"><span contenteditable="false">' + option.label + '</span></span>');
            }
        }

        return message;
    }

    insertPlaceholder( val, theObject, target) {
        let that = this.that;
        let element = that.$refs[target];
        let cursorStart = element.selectionStart;
        if (that[theObject][target] == null) {
            that[theObject][target] = '';
        }

        that[theObject][target] = that[theObject][target].substring(0, cursorStart) + val + that[theObject][target].substring(cursorStart, that[theObject][target].length);
    }
}

export default Placeholder;