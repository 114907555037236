<template>
	<div id="nodata" :style="{ 'background-image': 'url(' + vueUrl + '/media/state/nodata.png' + ')' }">
		<div class="row w-100">
			<div class="col-9">

			</div>
			<div class="col-2 lul">
<!--				You are confused? Me too. Just hit the god damn "+" at the top right corner so I can finally disappear.-->
			</div>
			<div class="col-1"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NoData',
	computed: {
		vueUrl: function() {
			return process.env.VUE_APP_URL;
		},
	},
};
</script>

<style scoped>
#nodata {
	height: 100%;
	width: 100%;
	min-height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
div.lul {
	font-family: "Comic Sans MS";
	font-size: 2rem;
}
</style>
