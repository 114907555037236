<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.MessageAdministration.Mail.title') }}</span>
			</h3>
			<div class="card-toolbar">
				<a href="" v-on:click.prevent="showMail('new')" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.add') ? 'disabled' : ''">
					<i class="fas fa-plus text-primary"></i>
				</a>
			</div>
		</div>
		<div class="card-body py-3">
			<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
			<NoData v-if="mails.length === 0 && !pageProcesses.isLoading"></NoData>
			<div class="col-12 prinor-table" v-if="mails.length > 0 && !pageProcesses.isLoading">
				<b-table ref="surveysTable" :data="mails" :paginated="true" :per-page="15" default-sort="created_at" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.name }}</span>
						</template>
					</b-table-column>
					<b-table-column field="created_at" :label="$t('VIEWS.Survey.All.createdAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.created_at).toLocaleDateString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="updated_at" :label="$t('GENERAL.General.updatedAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.updated_at).toLocaleDateString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="text-right" header-class="text-right" width="15rem">
						<template>
							<a href="" v-on:click.prevent="showMail(props.row.id)" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.edit') ? 'disabled' : ''" :title="$t('MENU.Survey.editSurvey')">
								<i class="fas fa-edit text-primary"></i>
							</a>
							<a href="" @click.prevent="onDelete(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.delete') ? 'disabled' : ''" :title="$t('VIEWS.Survey.All.delete')">
								<i class="far fa-trash-alt text-danger"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
		<b-modal
			ref="edit-modal"
			content-class="modal-border"
			size="xl"
			:title="$t('VIEWS.MessageAdministration.Mail.edit') + ': ' + mail.name"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Forms.save')"
			v-on:ok="onUpdate"
			v-on:hidden="resetForm"
			v-on:shown="initEditor"
			scrollable
		>
			<b-tabs content-class="mt-3" justified>
				<b-tab :title="$t('VIEWS.Administration.Right.Edit.General.title')" active>
					<div class="row">
						<div class="col-12 col-md-6">
							<b-form-group>
								<label>{{ $t('VIEWS.MessageAdministration.Mail.name') }}:</label>
								<b-input type="text" v-model="mail.name"></b-input>
							</b-form-group>
						</div>
						<div class="col-12 col-md-6">
							<b-form-group>
								<label>{{ $t('VIEWS.MessageAdministration.Mail.subject') }}:</label>
								<b-input type="text" v-model="mail.subject" id="mail-subject" ref="subject"></b-input>
							</b-form-group>
							<b-popover target="mail-subject" triggers="focus" placement="bottom" v-if="mail.personalised === true">
								<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
								<template v-for="(name, value) in personalPlaceholder">
									<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'mail', 'subject')" :key="'mail-subject-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
								</template>
							</b-popover>
						</div>
						<div class="col-12 col-md-6">
							<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.personalised')" v-slot="{ ariaDescribedby }">
								<b-form-radio-group v-model="mail.personalised" :aria-describedby="ariaDescribedby">
									<b-form-radio :value="false">{{ $t('GENERAL.General.no') }}</b-form-radio>
									<b-form-radio :value="true">{{ $t('GENERAL.General.yes') }}</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>
						<div class="col-12 col-md-6">
							<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.organization')" v-slot="{ ariaDescribedby }">
								<b-form-radio-group v-model="mail.organization" :aria-describedby="ariaDescribedby">
									<b-form-radio :value="false">{{ $t('GENERAL.General.no') }}</b-form-radio>
									<b-form-radio :value="true">{{ $t('GENERAL.General.yes') }}</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>
						<div class="col-12">
							<b-form-group :label="$t('GENERAL.General.Placeholder.placeholder')" v-if="placeholders != null && mail.personalised === true">
								<treeselect :multiple="false" v-model="selectedPlaceholder" :options="placeholders.options" :disable-branch-nodes="true" placeholder="#Platzhalter waehlen" v-on:select="selectPlaceholder" v-on:input="() => { this.selectedPlaceholder = null; }" :normalizer="normalizer"/>
<!--								<b-select :options="personalPlaceholder.options" value-field="value" text-field="label" class="ql-insertCustomTags" v-on:change="selectPlaceholder($event)"></b-select>-->
							</b-form-group>
							<b-form-group class="prinor-editor">
								<label>{{ $t('VIEWS.MessageAdministration.Mail.message') }}:</label>
								<div id="mail-message" ref="message" v-html="mailText">
								</div>
							</b-form-group>
<!--							<b-popover target="mail-message" triggers="focus" placement="bottom" v-if="mail.personalised === true">-->
<!--								<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>-->
<!--								<template v-for="(name, value) in personalPlaceholder">-->
<!--									<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'mail', 'message')" :key="'mail-message-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>-->
<!--								</template>-->
<!--							</b-popover>-->
						</div>
					</div>
				</b-tab>
				<b-tab :title="$t('GENERAL.General.advanced')" ref="advancedtab">
					<div class="row">
						<div class="col-12">
							<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.recipient')" v-slot="{ ariaDescribedby }">
								<b-form-radio-group v-model="mail.settings.recipient_type" :aria-describedby="ariaDescribedby">
									<b-form-radio value="subscriber">{{ $t('VIEWS.MessageAdministration.Mail.subscriber') }}</b-form-radio>
									<b-form-radio value="import">{{ $t('VIEWS.MessageAdministration.Mail.fromImport') }}</b-form-radio>
									<b-form-radio value="specific">{{ $t('VIEWS.MessageAdministration.Mail.specificMail') }}</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>
						<template v-if="mail.settings.recipient_type === 'specific'">
							<div class="col-12">
								<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.emailAddress')" v-slot="{ ariaDescribedby }">
									<b-input type="text" v-model="mail.settings.recipient_mail"></b-input>
								</b-form-group>
							</div>
						</template>
					</div>
					<div class="row">
						<div class="col-12">
							<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.theme')">
								<b-form-select v-model="mail.settings.theme" :options="themes" value-field="id" text-field="name"></b-form-select>
							</b-form-group>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.qrTitle')">
								<b-form-radio-group v-model="mail.settings.qr.add">
									<b-form-radio :value="false">{{ $t('GENERAL.General.no') }}</b-form-radio>
									<b-form-radio :value="true">{{ $t('GENERAL.General.yes') }}</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>
						<div class="col-12">
							<b-form-group label="#Kundenwallet erstellen">
								<b-form-radio-group v-model="mail.settings.qr.wallet">
									<b-form-radio :value="false">{{ $t('GENERAL.General.no') }}</b-form-radio>
									<b-form-radio :value="true">{{ $t('GENERAL.General.yes') }}</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</div>
						<template>
							<div class="col-12">
								<b-form-group :label="$t('GENERAL.General.Placeholder.placeholder')" v-if="placeholdersQr != null">
									<treeselect :multiple="false" :options="placeholdersQr.options" :disable-branch-nodes="true" placeholder="#Platzhalter waehlen" v-on:select="selectPlaceholderQr" v-on:input="() => { this.selectedPlaceholder = null; }" :normalizer="normalizer"/>
								</b-form-group>
							</div>
							<div class="col-12">
								<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.qrContent')" v-slot="{ ariaDescribedby }">
									<b-input type="text" v-model="mail.settings.qr.content"></b-input>
								</b-form-group>
							</div>
						</template>
					</div>
				</b-tab>
			</b-tabs>
		</b-modal>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import ContentLoading from '@/view/component/misc/ContentLoading';
import Swal from 'sweetalert2';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {mapGetters} from "vuex";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import Placeholder from "@/data/survey/Placeholder";
import NoData from "@/view/component/misc/NoData";
// import $ from 'jquery';

export default {
	name: 'EMails',
	components: {NoData, ContentLoading },
	data() {
		return {
			mails: [],
			surveys: [],
			themes: [],
			mail: {
				id: "",
				name: "",
				subject: "",
				message: "",
				personalised: false,
				organization: false,
				settings: {
					recipient_type: 'subscriber',
					recipient_mail: '',
					qr: {
						add: false,
						content: '',
					},
					theme: 'standard',
				}
			},
			quill: null,
			selectedPlaceholder: null,
			placeholders: null,
			placeholdersQr: null,
			mailText: '',
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('VIEWS.MessageAdministration.title'),
			},
			{ title: this.$t('VIEWS.MessageAdministration.Mail.title') },
		]);
		PRINOR_PROCESSES.isLoading();

		this.placeholders = new Placeholder(this);
		this.placeholdersQr = new Placeholder(this);
		this.onGet(true);

	},
	computed: {
		...mapGetters(['pageProcesses']),

		personalPlaceholder: function() {
			return new Placeholder(this);
		},
	},
	methods: {
		selectPlaceholder: function(node) {
			if (node.id != null) {
				let range = this.quill.getSelection(true);

				this.quill.insertEmbed(
					range.index,
					'TemplateMarker',
					{
						color: node.color,
						marker: node.id,
						title: node.label
					},
				);

				this.quill.insertText(range.index + 1, ' ', window.Quill.sources.USER);
				this.quill.setSelection(range.index + 2, window.Quill.sources.SILENT);
			}

			this.selectedPlaceholder = null;
		},
		selectPlaceholderQr: function(node) {
			if (node.id != null) {
				this.mail.qr.content += node.id;
			}
		},
		normalizer: function(node) {
			return {
				id: node.id,
				label: node.label,
				children: node.children,
			};
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		initEditor: function() {
			let that = this;

			let toolbarOptions = ['bold', 'italic', 'underline', 'strike'];

			let options = {
				modules: {
					toolbar: toolbarOptions
				},
				placeholder: '#E-Mail Nachricht eingeben...',
				theme: 'snow'
			};

			this.quill = new window.Quill('#mail-message', options);
			this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
				let ops = [];
				delta.ops.forEach(op => {
					if (op.insert && typeof op.insert === 'string') {
						ops.push({
							insert: op.insert
						});
					}
				});
				delta.ops = ops;
				return delta;
			})

			this.quill.on('text-change', function() {
				let delta = that.quill.getContents();
				let qdc = new window.QuillDeltaToHtmlConverter(delta.ops, window.opts_ || {});
				qdc.renderCustomWith(function(customOp){
					if (customOp.insert.type === 'TemplateMarker') {
						let val = customOp.insert.value;
						return val.marker;
					}
				});

				that.mail.message = qdc.convert();
			});
		},
		showMail: function(id) {
			if (id !== 'new') {
				let filtered = this.mails.filter(value => {
					return value.id === id;
				});
				if (filtered.length === 1) {
					this.mail = filtered[0];

					if (this.mail.company_id == null) {
						this.mail.organization = true;
					}
					else {
						this.mail.organization = false;
					}

					this.mailText = this.placeholders.replacePlaceholder(this.mail.message);

					if (!('qr' in this.mail.settings)) {
						this.mail.settings.qr = {
							add: false,
							content: '',
						};
					}

					if (!('wallet' in this.mail.settings.qr)) {
						this.mail.settings.qr.wallet = false;
					}

					if (!('theme' in this.mail.settings)) {
						this.mail.settings.theme = 'standard';
					}

					this.$refs['edit-modal'].show();
				}
			} else {
				this.mail.id = 'new';
				this.$refs['edit-modal'].show();
			}
		},
		onGet: function(rebuildPlaceholder) {
			this.isLoading = true;
			this.get().then(data => {
				this.mails = data.data.mails;
				this.surveys = data.data.surveys;
				this.themes = data.data.themes;
				if (rebuildPlaceholder === true) {
					this.placeholders.buildSurvey(this.surveys);
				}
				this.themes.push({
					id: 'plain',
					name: 'Nur Text (Ohne Design)',
				});
				this.themes.push({
					id: 'standard',
					name: this.$t('VIEWS.MessageAdministration.Mail.prinorquestTheme'),
				});
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('message-template/mail').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		resetForm: function() {
			this.mail = {
				id: "",
				name: "",
				subject: "",
				message: "",
				personalised: false,
				organization: false,
				settings: {
					recipient_type: 'subscriber',
					recipient_mail: '',
					qr: {
						add: false,
						wallet: false,
						content: '',
					},
					theme: 'standard',
				}
			};
			this.mailText = '';
		},
		onUpdate() {
			PRINOR_PROCESSES.isUpdating();
			this.update().then(() => {
				this.resetForm();
				this.onGet(false);
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('message-template/mail', this.mail).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
		delete: function(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('message-template/mail/' + id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		onDelete: function(id, name) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.All.delete'),
				text: this.$t('VIEWS.Survey.All.deleteText', { name: name }),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.delete(id).then(data => {
						if (data.success === true) {
							this.onGet(false);
						} else {
							PRINOR_TOASTS.delete.error(this);
						}
					});
				}
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
